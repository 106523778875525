import React from "react";
import { getCmsData } from "../../functions";

class HamburgerNavigation extends React.Component {
  constructor(props) {
    super(props);

    const cmsMenuData = getCmsData("megaMenu");
    if (!cmsMenuData) return null;
    this.categories = cmsMenuData.categories;

    const cmsHeaderData = getCmsData("headerDataCMS");
    if (!cmsHeaderData) return null;
    this.headerLinks = cmsHeaderData.headerLinks;

    this.toggleState = 0;
  }

  componentDidMount() {
    document
      .querySelectorAll(".hamburger-button")[0]
      .addEventListener("keypress", e => {
        this.openMainMenu(e);
      });

    document
      .querySelectorAll(".hamburger-button")[0]
      .addEventListener("mousedown", e => {
        this.openMainMenu(e);
      });
  }

  state = {
    level1: "closed",
    level2: "closed",
    level3: "closed"
  };

  openMainMenu = e => {
    var mainMenu = document.querySelectorAll(".level1-list")[0];
    if (e.key == "Enter" || e.type == "mousedown") {
      if (mainMenu.classList.contains("closed")) {
        mainMenu.classList.add("open");
        mainMenu.classList.remove("closed");
        this.activateBackground();
        document
          .querySelectorAll(".hamburger-button")[0]
          .classList.add("cross-button");
        document.querySelectorAll(".header-links")[0].classList.add("open");
        document
          .querySelectorAll(".header-links")[0]
          .classList.remove("closed");
      } else {
        mainMenu.classList.add("closed");
        mainMenu.classList.remove("open");
        this.deactivateBackground();
        document
          .querySelectorAll(".hamburger-button")[0]
          .classList.remove("cross-button");
        document.querySelectorAll(".header-links")[0].classList.add("closed");
        document.querySelectorAll(".header-links")[0].classList.remove("open");
      }
    }
  };

  openMenu = e => {
    if (e.key == "Enter" || e.type == "click") {
      var childList = e.target.parentNode.parentNode.querySelectorAll("ul")[0];
      var siblingLists = e.target.parentNode.parentNode.parentNode.querySelectorAll(
        "ul"
      );
      var siblingListsOpenButtons = e.target.parentNode.parentNode.parentNode.querySelectorAll(
        ".menu-open"
      );
      var siblingListsClosedButtons = e.target.parentNode.parentNode.parentNode.querySelectorAll(
        ".menu-close"
      );
      //Close sibling menus
      for (var i = 0; i < siblingLists.length; i++) {
        siblingLists[i].classList.add("closed");
        siblingLists[i].classList.remove("open");
      }
      //When sibling menus are closed, add 'open' menu button to those menus
      for (var i = 0; i < siblingListsOpenButtons.length; i++) {
        siblingListsOpenButtons[i].classList.remove("closed");
      }

      childList.classList.add("open");
      childList.classList.remove("closed");
      e.target.classList.add("closed");
      e.target.parentNode
        .querySelectorAll(".menu-close")[0]
        .classList.remove("closed");
    }
  };

  closeMenu = e => {
    if (e.key == "Enter" || e.type == "click") {
      var childList = e.target.parentNode.parentNode.querySelectorAll("ul")[0];
      childList.classList.add("closed");
      childList.classList.remove("open");

      var openButtons = document.querySelectorAll(
        "#hamburger-navigation .menu-open"
      );

      //Hide close button including children close buttons
      for (
        var i = 0;
        i <
        e.target.parentNode.parentNode.querySelectorAll(".menu-open").length;
        i++
      ) {
        e.target.parentNode.parentNode
          .querySelectorAll(".menu-open")
          [i].classList.remove("closed");
      }
      e.target.parentNode
        .querySelectorAll(".menu-close")[0]
        .classList.add("closed");
    }
  };

  activateBackground = e => {
    if (this.toggleState == 0) {
      let div = document.createElement("div");
      div.className = "blurBackground";
      document
        .querySelector("#hamburger-navigation")
        .parentNode.insertBefore(
          div,
          document.querySelector("#hamburger-navigation")
        );
      document.querySelector(".blurBackground").style.height =
        document.body.offsetHeight + "px";
      this.toggleState = 1;
    } else {
      document.querySelector(".blurBackground").style.height =
        document.body.offsetHeight + "px";
    }
  };

  deactivateBackground = e => {
    document.querySelector(".blurBackground").style.height = "0px";
  };

  render() {
    return (
      <nav id="hamburger-navigation">
        <ul className={"level1-list" + " " + this.state.level1}>
          {this.categories.map((categories, index) => (
            <li className="level1-item" key={categories.id}>
              <a href={categories.pageURL}>{categories.id}</a>
              {categories.pages.length > 0 ? (
                <span>
                  <span
                    className="menu-close closed"
                    aria-label="Open / close sub menu"
                    tabIndex="0"
                    onClick={e => this.closeMenu(e)}
                    onKeyDown={e => this.closeMenu(e)}
                  ></span>
                  <span
                    className="menu-open"
                    aria-label="Open / close sub menu"
                    tabIndex="0"
                    onClick={e => this.openMenu(e)}
                    onKeyDown={e => this.openMenu(e)}
                  ></span>
                </span>
              ) : null}

              <ul className={"level2-list" + " " + this.state.level2}>
                {categories.pages.map(level2Pages => (
                  <li key={level2Pages.pageName} className="level2-item">
                    <a href={level2Pages.pageURL}>{level2Pages.pageName}</a>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>

        <ul className="header-links">
          {this.headerLinks.map((links, index) => (
            <li key={index}>
              <a href={links.pageURL}>{links.pageName}</a>
            </li>
          ))}
        </ul>
      </nav>
    );
  }
} //End HamburgerNavigation class

export default HamburgerNavigation;
