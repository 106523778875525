import React from "react";
import SectionCard from './section-card/SectionCard';

class SectionCards extends React.Component {

    constructor(props){
        super()
    }    

  render() {
    return (
        <section id="section-cards">
            <div className="container-fluid">         
                <div className="row"> 

          {this.props.data.cards.map((item, index) => 
                <div key={index} className="col-sm-12 col-md-6 col-lg-4 section-card-container d-flex align-items-stretch">
                    <SectionCard data={item}/>
                </div>
          )} 
              </div>
            </div>
        </section>
    );
  }
}

export default SectionCards;