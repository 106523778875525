import React from "react";

class PageSummary extends React.Component {

	constructor(props){
		super()
		this.headingsArray = []
		this.headings()
	}

    headings(){
	    var h2 = document.querySelectorAll('main h2')
	    for(var i = 0; i < h2.length; i++){
	    	let data = {"headingName": h2[i].textContent}
	    	this.headingsArray.push(data)
	    	let idTag = "h2-" + i
	    	h2[i].setAttribute('id',idTag)
	    }
    }

    render() {
      return (
      <div id="page-summary">
        <h2>On this page</h2>
         <ul>
         {this.headingsArray.map((item, index) => 
           <li key={index}><a href={"#h2-" + index} key={index}>{item.headingName}</a></li>
          )}
          </ul>  
      </div>
    );
  }
}

export default PageSummary;
