import {
    submitButtonHandler,
    removeFromValidFieldTracker,
    exists
} from './FieldUtils';

/**
 * When a user selects the other option from the dropdown fields,
 * we need a text input field to allow them to specify what other
 * means to them.
 * 
 * @param {object} options 
 * 
 * @returns {object}
 */
const CreateOtherFields = options => {
    const fieldWrappers = Array.from(document.querySelectorAll(options.selectors.otherFieldWrapper));
    const other_field_classes = 'sq-form-field other-field__input mt-3 d-none';
    const other_field_placeholder = 'Please specify *';
    const submit = document.querySelector(options.selectors.submit);

    /**
     * Event listener being applied to the other field. Ensures
     * that the other field is always populated if it is ever
     * enabled.
     * 
     * @param {object}  field
     * @param {string}  otherFieldName
     * 
     * @returns {void}
     */
    const __watchOtherField = (field, otherFieldName) => {
        let valid = true;

        if (field.value.length === 0) {
            valid = false;
        }

        submitButtonHandler(otherFieldName, valid, submit);
    };

    /**
     * Toggle the other field on and off.
     * 
     * @param {boolean} show 
     * @param {boolean} field
     * @param {string}  otherFieldName
     * 
     * @returns {void}
     */
    const __showOtherField = (show, field, selectName) => {
        const otherFieldName = `other_field_${selectName}`;

        switch (show) {
            case true:
                field.classList.remove(options.classes.dNone);
                field.required = true;
                field.disabled = false

                submitButtonHandler(selectName, show, submit);
                submitButtonHandler(otherFieldName, !show, submit);
                field.addEventListener('keyup', () => { __watchOtherField(field, otherFieldName); });

                break;
            case false:
                field.classList.add(options.classes.dNone);
                field.required = false;
                field.disabled = true;
                field.value = '';

                removeFromValidFieldTracker(otherFieldName, submit);

                break;
        }
    };

    /**
     * Take the wrapper for the inputs that have an other field
     * attached, find the other field (last input field in DOM
     * selection). Set the classes and placeholder and return for
     * further use.
     * 
     * @param {object} wrapper
     * 
     * @returns {object}
     */
    const __setOtherField = wrapper => {
        const fields = wrapper.querySelectorAll('input');
        const field = fields[fields.length - 1];

        field.setAttribute('class', other_field_classes);
        field.setAttribute('placeholder', other_field_placeholder);

        return field;
    };

    /**
     * Apply event to the select field.
     * 
     * @param {object} wrapper 
     * 
     * @returns {void}
     */
    const __bindEvent = wrapper => {
        const select = wrapper.querySelector('select');
        const selectName = wrapper.dataset.name;
        const otherField = __setOtherField(wrapper);

        // Ensure the select field is tracked.
        submitButtonHandler(wrapper.dataset.name, false, submit);

        if (exists([select, otherField])) {
            select.addEventListener('change', () => {
                // If other is selected, show the other field and add it's property
                // to the field tracker. If any other option is selected, set the normal
                // select field tracker to valid. If the select is blank, we disable the other
                // field and it's prop in the tracker variable, while also setting the normal select
                // field tracker prop to false.
                if (select.value === 'other') {
                    __showOtherField(true, otherField, selectName);
                } else if (select.value.length > 0) {
                    submitButtonHandler(wrapper.dataset.name, true, submit);
                    __showOtherField(false, otherField, selectName);
                } else {
                    __showOtherField(false, otherField, selectName);
                    submitButtonHandler(wrapper.dataset.name, false, submit);
                }
            });
        }
    };

    /**
     * The publicly exposed init function
     * 
     * @return {void}
     */
    const init = () => {
        if (exists([fieldWrappers, submit])) {
            fieldWrappers.forEach(wrapper => {
                if (exists([wrapper])) {
                    __bindEvent(wrapper);
                }
            });
        }
    };

    /**
     * Return the publicly exposed init function.
     */
    return Object.freeze({
        init
    });
};

export default CreateOtherFields;