import React from "react";
import slug from "slug";
class PodcastFilter extends React.Component {

	constructor(props) {
		super()
		this.state = {}
    this.pods = []
    this.tags = []

    this.fnPodFilterChange = this.fnPodFilterChange.bind(this)
	}

  getTags() {
    
    // Get unique tags from pod data-tag arrays
    const _tags = Array.from(this.pods).reduce((acc, pod) => {
      return [
        ...acc, 
        ...JSON.parse(pod.getAttribute('data-tag') || '[]') 
          .filter(tag => !acc.includes(tag))
      ];
    }, [])
    
    _tags.sort();

    return ['All', ..._tags]
  }

  getCheckedFilters() {
    return Array.from(document.getElementsByName("cbx-pod-filter"))
    .filter(cbx => cbx.checked)
    .map(cbx => cbx.value);
  }

  /* Event listener to show/hide relevant pods based on selected tag */
  fnPodFilterChange(e = {}) {

    let cbxPodFiltersChecked = this.getCheckedFilters();
    
    // All selected logic
    const cbxPodFilterAll = document.getElementById('cbx-pod-filter-all');

    if (cbxPodFiltersChecked.length === 0) {

      cbxPodFilterAll.checked = true;
      cbxPodFiltersChecked = this.getCheckedFilters();

    } else if (e?.target?.value !== 'All') {

      cbxPodFilterAll.checked = false;
      cbxPodFiltersChecked = this.getCheckedFilters();

    } else if (cbxPodFiltersChecked.includes('All')) {

      const cbxPodFiltersAll = Array.from(document.getElementsByName("cbx-pod-filter"));
      cbxPodFiltersAll.forEach(cbx => {if (cbx.value !== 'All') cbx.checked = false});

    } 

    this.pods.forEach(pod => {
      const podTags = JSON.parse(pod.getAttribute('data-tag') || '[]');

      const podTagsINTERSECTcheckedFilters = podTags.filter(tag => cbxPodFiltersChecked.includes(tag));
      
      if (!(podTagsINTERSECTcheckedFilters.length > 0) && !cbxPodFiltersChecked.includes('All')) {
        pod.style.display = "none"
        return
      } 

      pod.style.display = "block"
    });
  }

  generateCheckboxes(tags) {

    return tags.map((tag) => {
      return (
        <>
          <div className="col-12 col-md-6 col-lg-4" key={tag}>  
            <div className="checkbox checkbox-standard">
              <input id={ `cbx-pod-filter-${slug(tag)}` } name="cbx-pod-filter" className="styled" value={tag} type="checkbox" onChange={this.fnPodFilterChange}/>
              <label htmlFor={ `cbx-pod-filter-${slug(tag)}` }>{tag}</label>
            </div>
          </div>
        </>
      );
    });
  }

  componentDidMount() {
    
    this.fnPodFilterChange();
  }

  render() {
    this.pods = document.querySelectorAll(".podcast-component");
    this.tags = this.getTags();
    return (
      <div className="row">
        {this.generateCheckboxes(this.tags)}
      </div>
    );
  }
}

export default PodcastFilter ;
