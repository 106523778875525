import { exists, submitButtonHandler } from "./FieldUtils";

/**
 * Create the confirm password field. This is used
 * primarily for the reset password area.
 * 
 * @param {object} options 
 * 
 * @returns {object}
 */
const CreateConfirmPasswordField = options => {
    const confirmPassword = document.querySelector(options.selectors.confirmPassword);
    const password = document.querySelector(options.selectors.password);
    const passwordError = document.querySelector(options.selectors.passwordError);
    const submit = document.querySelector(options.selectors.submit);

    /**
     * Toggle the password confirmation error depending on the value of hide.
     * 
     * @param {boolean} hide 
     * 
     * @returns {void}
     */
    const __hidePasswordConfirmationError = hide => {
        if (!hide) {
            passwordError.classList.remove(options.classes.dNone);
            passwordError.classList.add(options.classes.dBlock);
        } else {
            passwordError.classList.add(options.classes.dNone);
            passwordError.classList.remove(options.classes.dBlock);
        }
    };
    
    /**
     * Determine whether the password fields match.
     * 
     * @returns {void}
     */
    const __validateMatch = () => {
        const originalPassword = password.value;
        const confirmPasswordValue = confirmPassword.value;
        let valid = true;

        if (confirmPasswordValue !== originalPassword) {
            valid = false;
        }

        __hidePasswordConfirmationError(valid);
        submitButtonHandler('confirm_password', valid, submit);
    };
    
    /**
     * Bind our event listeners to the fields.
     * 
     * @returns {void}
     */
    const __bind = () => {
        submitButtonHandler('confirm_password', false, submit);

        password.addEventListener('keyup', () => {
            __validateMatch();
        });
        
        confirmPassword.addEventListener('keyup', () => {
            __validateMatch();
        });
    }

    /**
     * Initialise the component if we have all the required components.
     * 
     * @returns {void}
     */
    const init = () => {
        const requiredComponents = [password, confirmPassword];
        
        if (exists(requiredComponents)) {
            __bind();
        }
    };
    
    /**
     * Return the publicly exposed init function.
     */
    return Object.freeze({
        init,
    });
};

export default CreateConfirmPasswordField;