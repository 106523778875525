/* -------------------------------------------------------------------------- */
/*                                   Imports                                  */
/* -------------------------------------------------------------------------- */

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/features/array/includes";
import "core-js/features/array/find";
import "core-js/features/promise";
import "whatwg-fetch";
import "./styles/styles.scss";
import { renderWithErrorBoundary } from "./functions";
import "./components/traceability-form/index";

/* -------------------------------------------------------------------------- */
/*                                Image assets                                */
/* -------------------------------------------------------------------------- */

function requireAll(r) {
  r.keys().forEach(r);
}
requireAll(require.context("./images/", true));

/* -------------------------------------------------------------------------- */
/*                               Dynamic imports                              */
/* -------------------------------------------------------------------------- */

/* ----------------------- Homepage components/modules ---------------------- */

if (document.querySelector("#homepage")) {
  import(
    /* webpackChunkName: "chunk.homepage" */
    "./homepage"
  ).then(module => module.default());
}

/* ----------------------- Content components/modules ----------------------- */

import(
  /* webpackMode: "eager" */
  "./content"
).then(module => module.default());

/* ------------------------ Global components/modules ----------------------- */

const skipTarget = document.querySelector("#skip-to-content-react");
if (skipTarget) {
  import(
    /* webpackChunkName: "chunk.global" */
    "./components/skip-to-content/SkipToContent"
  ).then(module => {
    renderWithErrorBoundary(module.default, skipTarget);
  });
}

const breakpointsTarget = document.querySelector("#breakpoints-react");
if (breakpointsTarget) {
  import(
    /* webpackChunkName: "chunk.global" */
    "./components/breakpoints/Breakpoints"
  ).then(module => {
    renderWithErrorBoundary(module.default, breakpointsTarget);
  });
}

const backToTopTarget = document.querySelector("#back-to-top-react");
if (backToTopTarget) {
  import(
    /* webpackChunkName: "chunk.global" */
    "./components/back-to-top/BackToTop"
  ).then(module => {
    renderWithErrorBoundary(module.default, backToTopTarget);
  });
}

const footerTarget = document.querySelector("#footer-react");
if (footerTarget) {
  import(
    /* webpackChunkName: "chunk.global" */
    "./components/footer/Footer"
  ).then(module => {
    renderWithErrorBoundary(module.default, footerTarget);
  });
}

const acknowledgementTarget = document.querySelector("#acknowledgement-react");
if (acknowledgementTarget) {
  import(
    /* webpackChunkName: "chunk.global" */
    "./components/acknowledgement/Acknowledgement"
  ).then(module => {
    renderWithErrorBoundary(module.default, acknowledgementTarget);
  });
}

if (document.querySelectorAll(".cm-modal-react").length > 0) {
  import(
    /* webpackChunkName: "chunk.global" */
    "./components/cm-modal/CmModal"
  ).then(module => {});
}

const alertsTarget = document.querySelector("#alerts-react");
if (alertsTarget) {
  import(
    /* webpackChunkName: "chunk.global" */
    "./components/alerts/Alerts"
  ).then(module => {
    renderWithErrorBoundary(module.default, alertsTarget);
  });
}

const agStrategyMapTarget = document.querySelector("#ag-strategy-map-react");
if (agStrategyMapTarget) {
  import(
    /* webpackChunkName: "chunk.global" */
    "./components/maps/AgStrategyMap/AgStrategyMap"
  ).then(module => {
    renderWithErrorBoundary(module.default, agStrategyMapTarget);
  });
}

const headerTarget = document.querySelector("#header-react");
if (headerTarget) {
  import(
    /* webpackChunkName: "chunk.global" */
    "./components/header/Header"
  ).then(module => {
    renderWithErrorBoundary(module.default, headerTarget);
  });
}

const primNavTarget = document.querySelector("#primary-navigation-react");
if (primNavTarget) {
  import(
    /* webpackChunkName: "chunk.global" */
    "./components/primary-navigation/PrimaryNavigation"
  ).then(module => {
    renderWithErrorBoundary(module.default, primNavTarget);
  });
}

const hamNavTarget = document.querySelector("#hamburger-navigation-react");
if (hamNavTarget) {
  import(
    /* webpackChunkName: "chunk.global" */
    "./components/hamburger-navigation/HamburgerNavigation"
  ).then(module => {
    renderWithErrorBoundary(module.default, hamNavTarget);
  });
}
