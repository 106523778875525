import React from "react";

class SocialIconsGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      facebook:
        "https://facebook.com/sharer.php?u=" +
        window.location.href +
        "&" +
        "t=",
      email:
        "mailto:?subject=Agriculture Victoria Website Content:" +
        props.data.pageName +
        "&" +
        "body=Hi. I found some information that might interest you:" +
        props.data.pageName +
        " - " +
        window.location.href,
      print: "javascript:window.print()",
      twitter:
        "https://twitter.com/share?text=" +
        props.data.pageName +
        "&" +
        "url=" +
        window.location.href,
    };
    if (props.data.location == "footer") {
      this.updateForFooter();
    }
  }

  componentDidMount = () => {
    window.onpopstate = (event) => {
      setTimeout(() => {
        if (props.data.location == "top") {
          this.updateForSearchPage();
        }
      }, 1000);
    };
  };

  updateForSearchPage = () => {
    this.state = {
      facebook:
        "https://facebook.com/sharer.php?u=" +
        window.location.href +
        "&" +
        "t=",
      twitter:
        "https://twitter.com/share?text=" +
        props.data.pageName +
        "&" +
        "url=" +
        window.location.href,
      email:
        "mailto:?subject=Agriculture Victoria Website Content:" +
        this.props.data.pageName +
        "&" +
        "body=Hi. I found some information that might interest you:" +
        this.props.data.pageName +
        " - " +
        window.location.href,
    };
    document
      .querySelector("#facebook-social-link")
      .setAttribute("href", this.state.facebook);
    document
      .querySelector("#twitter-social-link")
      .setAttribute("href", this.state.twitter);
    document
      .querySelector("#email-link")
      .setAttribute("href", this.state.email);
  };

  updateForFooter = () => {
    this.state = {
      facebook: this.props.data.facebook,
      twitter: this.props.data.twitter,
      youtube: this.props.data.youtube,
    };
  };

  render() {
    return (
      <div className="social-icons-group">
        {this.props.data.location == "top" && (
          <a
            href={this.state.email}
            id="email-link"
            className="social-link"
            aria-label="Email this page"
            target="_blank"
          >
            <svg width="28px" height="25px" viewBox="0 0 22 16">
              <g
                id="icon-email"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fill-rule="evenodd"
              >
                <path
                  d="M20,13.2 C20,13.642 19.6599,14 19.24,14 L1.76,14 C1.3401,14 1,13.642 1,13.2 L1,2.8 C1,2.358 1.3401,2 1.76,2 L19.24,2 C19.6599,2 20,2.358 20,2.8 L20,13.2 Z"
                  id="Stroke-1"
                ></path>
                <path d="M14,8 L20,14" id="Stroke-3" stroke="#ffffff"></path>
                <path d="M1,14 L7,8" id="Stroke-5" stroke="#ffffff"></path>
                <path
                  d="M20,2 L11.3454015,10.6500169 C10.8781593,11.116661 10.121054,11.116661 9.65381192,10.6500169 L1,2"
                  id="Stroke-7"
                  stroke="#ffffff"
                ></path>
              </g>
            </svg>
          </a>
        )}
        {this.props.data.location == "top" && (
          <a
            href={this.state.print}
            id="print-link"
            className="social-link"
            aria-label="Print this page"
          >
            <svg width="28px" height="25px" viewBox="0 0 18 18">
              <g
                id="icon-printer"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fill-rule="evenodd"
              >
                <path
                  d="M17.3897,9 C17.78835,9 18,9.336 18,9.72223529 L18,15.0974118 C18,15.4836471 17.78835,16 17.3897,16 L1.72165,16 C1.323,16 1,15.4836471 1,15.0974118 L1,9.72223529 C1,9.336 1.323,9 1.72165,9 L17.3897,9 Z"
                  id="Stroke-1"
                  stroke="#ffffff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M4,8.72253788 L4,2.73695821 C4,2.34284943 4.33713352,2 4.75322725,2 L14.0093167,2 C14.4254104,2 14.6463216,2.34284943 14.6463216,2.73695821 L14.6463216,8.72253788"
                  id="Stroke-5"
                  stroke="#ffffff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M6.11156794,6 L12.765519,6"
                  id="Stroke-9"
                  stroke="#ffffff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M3.32438151,15.9411103 L3.32438151,13.8516615 C3.32438151,13.4575527 3.5277869,13 3.91090789,13 L14.8841792,13 C15.2673002,13 15.5777181,13.4575527 15.5777181,13.8516615 L15.5777181,15.9411103"
                  id="Stroke-3"
                  stroke="#ffffff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M6.11156794,4 L10.5475353,4"
                  id="Stroke-7"
                  stroke="#ffffff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M16.1554362,10.5777181 C16.1554362,10.8966185 15.8966185,11.1554362 15.5777181,11.1554362 C15.2588177,11.1554362 15,10.8966185 15,10.5777181 C15,10.2588177 15.2588177,10 15.5777181,10 C15.8966185,10 16.1554362,10.2588177 16.1554362,10.5777181"
                  id="Fill-11"
                  fill="#202228"
                ></path>
              </g>
            </svg>
          </a>
        )}

        <a
          href={this.state.facebook}
          id="facebook-social-link"
          className="social-link"
          aria-label="Facebook"
          target="_blank"
        >
          <svg width="23px" height="23px" viewBox="0 0 20 20">
            <g id="Layer_2" data-name="Layer 2">
              <g id="Footer">
                <path
                  id="Facebook_logo"
                  data-name="Facebook logo"
                  className="cls-1"
                  d="M18.9,0H1.1A1.1,1.1,0,0,0,0,1.1V18.9A1.1,1.1,0,0,0,1.1,20h9.58V12.25H8.08v-3h2.6V7a3.64,3.64,0,0,1,3.88-4,20.26,20.26,0,0,1,2.33.12v2.7H15.3c-1.26,0-1.5.6-1.5,1.47V9.24h3l-.39,3H13.8V20h5.1A1.1,1.1,0,0,0,20,18.9V1.1A1.1,1.1,0,0,0,18.9,0Z"
                />
              </g>
            </g>
          </svg>
        </a>

        <a
          href={this.state.twitter}
          id="twitter-social-link"
          className="social-link"
          aria-label="X"
          target="_blank"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31px"
            height="23px"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
          </svg>
        </a>

        {this.props.data.location == "footer" && (
          <a
            href={this.props.data.youtube}
            className="social-link"
            aria-label="YouTube"
            target="_blank"
          >
            <svg width="31px" height="23px" viewBox="0 0 31 23">
              <g id="youtube" stroke="none" strokeWidth="1" fillRule="evenodd">
                <path
                  d="M12,17 L12,7 L20,12.0001911 L12,17 Z M30.3521705,3.59152419 C29.9956705,2.17776613 28.9451932,1.06449194 27.6114886,0.68666129 C25.194017,0 15.5,0 15.5,0 C15.5,0 5.80598295,0 3.38851136,0.68666129 C2.05480682,1.06449194 1.00432955,2.17776613 0.647829545,3.59152419 C0,6.15379839 0,11.5 0,11.5 C0,11.5 0,16.8462016 0.647829545,19.4084758 C1.00432955,20.8222339 2.05480682,21.9355081 3.38851136,22.3135242 C5.80598295,23 15.5,23 15.5,23 C15.5,23 25.194017,23 27.6114886,22.3135242 C28.9451932,21.9355081 29.9956705,20.8222339 30.3521705,19.4084758 C31,16.8462016 31,11.5 31,11.5 C31,11.5 31,6.15379839 30.3521705,3.59152419 Z"
                  id="YouTube"
                ></path>
              </g>
            </svg>
          </a>
        )}
      </div>
    );
  }
}

export default SocialIconsGroup;
