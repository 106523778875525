import React from "react";

class BlockQuoteContent extends React.Component {
  state = {
    quote: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac semper eros. 
Phasellus sit amet urna ultricies, efficitur nibh pharetra, interdum elit. Aliquam erat volutpat.</p>
<p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. 
Curabitur a felis in nunc fringilla tristique. Nulla facilisi. Donec vitae nibh id arcu mattis tincidunt.</p>`,
    attributor_name: "Attributor Name",
    attributor_title: "Attributor Title",
    attributor_location: "Attributor Location",
  };

  render() {
    return (
      <div className="quote-container">
        <figure>
          <div className="quote">
            <svg
              width="30"
              height="23"
              viewBox="0 0 30 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="quote-icon icon-left"
              aria-hidden="true"
            >
              <path
                d="M0 8.27723C0 3.70594 3.69375 0 8.25 0H9H12V6.0198H9H8.25C7.00313 6.0198 6 7.02624 6 8.27723V9.0297H13.5V22.5743H7.07812H0V15.802V9.0297V8.27723Z"
                fill="#00573F"
              />
              <path
                d="M16.5 8.27723C16.5 3.70594 20.1938 0 24.75 0H25.5H28.5V6.0198H25.5H24.75C23.5031 6.0198 22.5 7.02624 22.5 8.27723V9.0297H30V22.5743H16.5V15.802V9.0297V8.27723Z"
                fill="#00573F"
              />
            </svg>
            <blockquote className="quote-block">
              <div className="quote-text">
                <p dangerouslySetInnerHTML={{ __html: this.state.quote }} />
              </div>
            </blockquote>

            <svg
              width="30"
              height="23"
              viewBox="0 0 30 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="quote-icon icon-right"
              aria-hidden="true"
            >
              <path
                d="M21.75 23C26.3062 23 30 19.3062 30 14.75V14V7.25V0.5H16.5V14H24V14.75C24 15.9969 22.9969 17 21.75 17H21H18V23H21H21.75ZM5.25 23C9.80625 23 13.5 19.3062 13.5 14.75V14V7.25V0.5H0V14H7.5V14.75C7.5 15.9969 6.49687 17 5.25 17H4.5H1.5V23H4.5H5.25Z"
                fill="#00573F"
              />
            </svg>
          </div>
          <div className="quote-attribution">
            <div
              className="attributor-name"
              dangerouslySetInnerHTML={{ __html: this.state.attributor_name }}
            />

            <div
              className="attributor-title"
              dangerouslySetInnerHTML={{
                __html: this.state.attributor_title,
              }}
            />

            <div
              className="attributor-location"
              dangerouslySetInnerHTML={{
                __html: this.state.attributor_location,
              }}
            />
          </div>
        </figure>
      </div>
    );
  }
}

export default BlockQuoteContent;
