import React from "react";
import classNames from "classnames";

class CardGrid extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.equalCardHeight();
    setTimeout(
      function() {
        this.equalCardHeight();
      }.bind(this),
      1000
    );

    window.onresize = this.equalCardHeight;
  }

  equalCardHeight() {
    let Items = document.querySelectorAll("#card-grid .single-card");
    let tallest = 0;

    for (var i = 0; i < Items.length; i++) {
      Items[i].style.height = "auto";
    }

    for (var i = 0; i < Items.length; i++) {
      if (Items[i].clientHeight > tallest) {
        tallest = Items[i].clientHeight;
      }
    }
    for (var i = 0; i < Items.length; i++) {
      Items[i].style.height = tallest + "px";
    }
  }

  render() {
    return (
      <div id="card-grid" class={this.props.inverse && "inverse"}>
        <div className="container-fluid">
          <div className="row">
            {this.props.data.map((item, index) => {
              const cardClassName = classNames("single-card", {
                "intl-font-support": item.intlFontSupport === "enabled"
              });
              return (
                <div
                  className="col-12  col-md-6 col-lg-6  col-xl-4 d-flex align-items-stretch"
                  key={"single-card-" + index}
                >
                  <div className={cardClassName} dir={item.textDirection}>
                    <a href={item.URL}>
                      {item.displayImage == "yes" && (
                        <div className="image-container">
                          <div
                            className="image"
                            aria-label=""
                            style={{
                              backgroundImage: `url(${item.image})`,
                              transform: "scale(1.2)"
                            }}
                          >
                            <span>{item.heading}</span>
                          </div>
                        </div>
                      )}
                      <div className="content-container">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                        <div className="arrow-container">
                          <span className="arrow-right"></span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default CardGrid;
