// The regex patterns we use to validate the password.
const password_rules = {
    character_length: 8,
    special_characters: new RegExp(/[ !"#$%&'()*+,-./:;<=>?@\\^_`[\]{|}~]/),
    capital_chars: new RegExp(/[A-Z]/),
    intiger_chars: new RegExp(/\d/),
};

// Watches for registration form errors.
const valid_fields = {
    description: true,
    confirm_email: false,
    password: false,
    privacy: false,
};

/**
 * Check all fields on load. Ensure that the correct ones are being displayed.
 * Use the field utility functions to toggle the submit button on if all fields
 * are valid.
 * 
 * @returns {void}
 */
export const checkAllFieldsOnLoad = submit => {
    if (typeof valid_fields == 'object') {
        const fieldTypes = ['select', 'input[type="text"]', 'input[type="checkbox"]', 'input[type="email"]'];
        const otherSelectFieldKeys = ['industry', 'supply']
        let fields = [];

        // Loop through all the keys in the valid_fields object. Grab the wrapper for each field.
        for (const KEY in valid_fields) {
            let fieldWrapper = document.querySelector(`[data-name="${KEY}"]`);
            
            // Using the field types array, identify the fields we want to check.
            fieldTypes.forEach(type => {
                let field = fieldWrapper.querySelector(type);
                
                // If the field exists, push to fields array. Also, modify the key if it is an
                // other field.
                if (field instanceof HTMLElement) {
                    if (field.tagName === 'INPUT' && otherSelectFieldKeys.includes(KEY)) {
                        fields.push({key: `other_field_${KEY}`, field});
                    } else {
                        fields.push({key: KEY, field});
                    }
                }
            });
        }

        // If we have a populated fields array loop through it.
        if (fields.length > 0) {
            fields.forEach(field => {
                let valid = false;

                // If we have a populated field, it's valid.
                if (field.field.value.length > 0) {
                    valid = true;
                }
                
                // Description field is always set to true.
                if (field.key === 'description') {
                    valid = true;
                }
                
                // ALways purge the other fields from the valid_fields tracker.
                // Submit all remaining fields to be tracked.
                if (field.key.includes('other_field_')) {
                    if (field.field.value.length > 0) {
                        field.field.classList.remove('d-none');
                    } else {
                        removeFromValidFieldTracker(field.key, submit);
                    }
                } else {
                    submitButtonHandler(field.key, valid, submit);
                }
            });
        }
    }
};

/**
 * Remove the field from the valid field tracker.
 * 
 * @param {string}  prop
 * @param {object}  submit
 * 
 * @returns {void}
 */
export const removeFromValidFieldTracker = (prop, submit) => {
    delete valid_fields[prop];
    toggleSubmit(submit);
};

/**
 * Purge valid field tracking array.
 * 
 * @returns {void}
 */
export const purgeValidFieldTracker = () => {
    for (const KEY in valid_fields) {
        delete valid_fields[KEY];
    }
}

/**
 * This will control whether we enable/disable the submit
 * button.
 * 
 * @param {string}  field
 * @param {boolean} valid
 * @param {object}  submit
 * 
 * @returns {void}
 */
export const submitButtonHandler = (field, valid, submit) => {
    valid_fields[`${field}`] = valid;
    toggleSubmit(submit);
    
    // console.log(valid_fields);
};

/**
 * Toggle the submit button visibility based on whether
 * all props in the field tracker object are valid.
 * 
 * @param {object} submit 
 * 
 * @returns {void}
 */
const toggleSubmit = submit => {
    let allFieldsAreValid = true;
    
    for (const KEY in valid_fields) {
        if (valid_fields[`${KEY}`] === false) {
            allFieldsAreValid = false;
        }
    }

    !allFieldsAreValid ?
        submit.setAttribute('title', 'Please fill in all required fields') :
        submit.setAttribute('title', 'Press to submit the form.')
    ;
    
    submit.disabled = !allFieldsAreValid;
};

/**
 * Identify if the password is as least 8 characters long.
 * 
 * @param {string} value 
 * 
 * @returns {boolean}
 */
export const tooShort = value => {
    return value.length < password_rules.character_length;
};

/**
 * Identify if the password has at least one capital letter.
 * 
 * @param {string} value 
 * 
 * @returns {boolean}
 */
export const noCapitalChars = value => {
    return !password_rules.capital_chars.test(value);
};

/**
 * Identify if the password has at least one integer.
 * 
 * @param {string} value 
 * 
 * @returns {boolean}
 */
export const noIntegerChars = value => {
    return !password_rules.intiger_chars.test(value);
};

/**
 * Identify if the password has at least one special character.
 * 
 * @param {string} value
 * 
 * @returns {boolean}
 */
export const noSpecialChars = value => {
    return !password_rules.special_characters.test(value);
}

/**
* Takes in an array of required items and confirms whether everything exists.
* 
* @param {array} items 
* 
* @returns {boolean}
*/
export const exists = items => {
    let valid = true;

    items.forEach(item => {
        if (item === undefined || item === null || item.length === 0) {
            valid = false;
        }
    });

    return valid;
};

export const setPlaceholders = (fields) => {
    fields.forEach(field => {
        const html = document.querySelector(`#${field.name}`);
        
        if (exists([html])) {
            html.placeholder = field.placeholder;
        }
    });
};