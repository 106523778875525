import React from "react";

class OverlayAnimalWelfareSVG extends React.Component {
  render() {
    return (
      <div>
        <div className="banner__image-triangle">
          <div className="triangle-overlay-container">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 226.89 480">
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <polygon
                    className="cls-1"
                    fill="#FDF4EC"
                    points="0 480 226.9 0 0 0 0 480"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
        <div className="polygon-diagnol">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 497.12 480.36">
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_4" data-name="Layer 4">
                <polygon
                  id="Hero_banner_background"
                  data-name="Hero banner background"
                  className="cls-1"
                  fill="#B8E9EC"
                  points="400.99 0 496.91 0 496.91 480 174.59 480 400.99 0"
                />
                <polygon
                  className="cls-2"
                  fill="#00B7BD"
                  points="174.39 480 248.22 480 211.3 401.91 174.39 480"
                />
                <polygon
                  id="Hero_banner_background-2"
                  data-name="Hero banner background"
                  className="cls-3"
                  fill="#000000"
                  opacity=".5"
                  points="226.9 0 401.3 0 174.41 480 0 480 226.9 0"
                />
                <polygon
                  id="Hero_banner_background-3"
                  data-name="Hero banner background"
                  className="cls-4"
                  opacity=".2"
                  fill="#B8E9EC"
                  points="248.06 480.36 497.12 480.36 497.12 0.36 21.16 0.36 248.06 480.36"
                />
                <polyline
                  id="Hero_banner_background-4"
                  data-name="Hero banner background"
                  className="cls-5"
                  fill="#ccc"
                  opacity=".5"
                  points="21.12 0.36 226.41 0.36 124.12 217.36"
                />
              </g>
            </g>
          </svg>
        </div>
      </div>
    );
  }
}

export default OverlayAnimalWelfareSVG;
