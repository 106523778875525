import React from "react";

class OverlayHomepageSVG extends React.Component {
  render() {
    return (
      <div>
        <div className="banner__image-triangle">
          <div className="triangle-overlay-container">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 226.89 480">
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <polygon
                    className="cls-1"
                    fill="#e3edeb"
                    points="0 480 226.9 0 0 0 0 480"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
        <div className="polygon-diagnol">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496.91 480">
            <defs></defs>
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_2-2" data-name="Layer 2">
                <polygon
                  id="Hero_banner_background"
                  data-name="Hero banner background"
                  className="cls-1"
                  fill="#00573f"
                  points="400.99 0 496.91 0 496.91 480 174.59 480 400.99 0"
                />
                <polygon
                  className="cls-2"
                  fill="#003a28"
                  points="174.39 480 248.22 480 211.3 401.91 174.39 480"
                />
                <polygon
                  id="Hero_banner_background-2"
                  data-name="Hero banner background"
                  className="cls-3"
                  opacity=".5"
                  fill="#00573f"
                  points="226.9 0 401.3 0 174.41 480 0 480 226.9 0"
                />
              </g>
            </g>
          </svg>
        </div>
      </div>
    );
  }
}

export default OverlayHomepageSVG;
