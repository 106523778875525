import React from "react";
import StandardCard from '../../standard-card/StandardCard';

class AnimalWelfareListing extends React.Component {
	constructor(props){
		super()
	}

    render() {
      return (
		<section id="animal-welfare-listing">
		    <div className="container-fluid">
		        <div className="row">
					{this.props.data.cards.map((item, index) =>
						<div key={index} className="col-sm-12 col-md-6 col-lg-6 section-card-container d-flex align-items-stretch">
					      <StandardCard data={item}/>
					    </div>
					)}
			    </div>		
		    </div>
		</section>
    );
  }
}

export default AnimalWelfareListing;
